import React, { useRef } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { FiLoader } from 'react-icons/fi';

import { Box, Button, keyframes, OutlinedInput, useTheme } from '@mui/material';

import Tagify from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css';

import i18next from '../../languages/i18n.config';
import { Loader } from '../Loader';
import { getParticipantsUserListForMentions } from '../../utils/kolab/attendees.utils';
import { useAdBoardContext } from '../../context';

interface MentionInputProps {
  setValue: UseFormSetValue<{
    message: string;
  }>;
  control: Control<
    {
      message: string;
    },
    any
  >;
}

export const MentionInput = ({ setValue, control }: MentionInputProps) => {
  const theme = useTheme();

  const {
    allParticipantsData,
    isLoading: isContextLoading,
    isError: isContextError,
    userId,
  } = useAdBoardContext();

  const mentionnableUsers = getParticipantsUserListForMentions(
    allParticipantsData,
    userId
  );

  type CustomTagData = {
    id: string;
    value: string;
  };

  const tagifyRef = useRef<Tagify<CustomTagData> | null>(null);

  const whitelist: CustomTagData[] = mentionnableUsers;

  //   if (isContextError) return <>Something went wrong</>;
  //   if (isContextLoading) return <Loader />;

  // Define the keyframes for the spin animation
  const spin = keyframes`
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    `;

  return (
    <Controller
      name='message'
      control={control}
      defaultValue=''
      render={({ field: { value, onChange, ref } }) => (
        <OutlinedInput
          id='discussion-message-message-input'
          aria-describedby='discussion-message-message-input'
          inputRef={(inputRef) => {
            if (inputRef && !tagifyRef.current) {
              tagifyRef.current = new Tagify<CustomTagData>(inputRef, {
                mode: 'mix',
                pattern: /@/,
                whitelist,
                dropdown: {
                  enabled: 0,
                  position: 'text',
                  highlightFirst: true,
                },
              });

              tagifyRef.current.on('change', (e) => {
                const newValue = e.detail.value;
                console.log(newValue);

                onChange(newValue); // Update react-hook-form value
                setValue('message', newValue);
              });
            }
            ref(inputRef); // Pass MUI ref
          }}
          placeholder={i18next.t('forms.helperMessage')}
          value={value} // Bind value to react-hook-form
          onChange={(e) => {
            // Ensure value updates correctly if Tagify doesn't catch it
            onChange(e.target.value);
            setValue('message', e.target.value);
          }}
          fullWidth
          multiline
          sx={{
            display: 'flex',
            minHeight: '67px',
            height: '100%',
            position: 'relative',
            borderRadius: '8px',
            borderColor: theme.palette.keyops.gray[400],
            '.tagify__input': {
              width: '80%',
              height: '100%',
              p: 0,
              m: 0,
            },
            tags: {
              display: 'flex',
              alignContent: 'center',
              width: '80%',
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.keyops.blue.midnightBlue,
                borderWidth: '1px',
              },
            },
            p: '16px !important',
          }}
          endAdornment={
            <Button
              type='submit'
              variant='outlined'
              sx={{
                position: 'absolute',
                right: '8px',
                bottom: '8px',
                color: theme.palette.keyops.blue.midnightBlue,
                borderColor: theme.palette.keyops.blue.midnightBlue,
                '&:hover': {
                  borderColor: theme.palette.keyops.blue.midnightBlue,
                  backgroundColor: theme.palette.keyops.blue.midnightBlue,
                  color: 'white',
                },
              }}
              endIcon={
                isContextLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      animation: `${spin} 3s linear infinite`, // Apply the spin animation
                    }}
                  >
                    <FiLoader />
                  </Box>
                )
              }
            >
              {i18next.t('common.send')}
            </Button>
          }
        />
      )}
    />
  );
};
