import React, { useCallback, useState } from 'react';
import {
  List,
  EditButton,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  TopToolbar,
  FilterButton,
  ListProps,
  Button,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InboxRoundedIcon from '@mui/icons-material/InboxRounded';

import AdBoardCreate from './Create';

const adBoardFilters = [
  <TextInput key='search' label='Search' source='q' alwaysOn />,
  <ReferenceInput
    key='companyId'
    source='companyId'
    reference='Company'
    label='Company'
  >
    <AutocompleteInput optionText='name' />
  </ReferenceInput>,
];

const ListActions: React.FC<{ onOpenCreateDialog: () => void }> = ({
  onOpenCreateDialog,
}) => (
  <TopToolbar>
    <FilterButton />
    <Button
      label='Create'
      startIcon={<AddIcon />}
      onClick={onOpenCreateDialog}
    />
  </TopToolbar>
);

const Empty: React.FC<{ onOpenCreateDialog: () => void }> = ({
  onOpenCreateDialog,
}) => (
  <Box
    display={'flex'}
    flexDirection={'column'}
    flexGrow={1}
    justifyContent={'center'}
    alignItems={'center'}
    m={1}
  >
    <InboxRoundedIcon sx={{ fontSize: 180, color: 'GrayText' }} />
    <Typography variant={'h3'} color={'GrayText'} paragraph>
      No Ad Boards yet.
    </Typography>
    <Button
      label={'Create'}
      variant={'contained'}
      startIcon={<AddIcon />}
      onClick={onOpenCreateDialog}
    />
  </Box>
);

const AdBoardList: React.FC<ListProps> = (props) => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => setShowCreateDialog(true), []);
  const closeCreateDialog = useCallback(() => setShowCreateDialog(false), []);

  return (
    <>
      <List
        actions={<ListActions onOpenCreateDialog={openCreateDialog} />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        filters={adBoardFilters}
        perPage={25}
        empty={<Empty onOpenCreateDialog={openCreateDialog} />}
        {...props}
      >
        <Datagrid rowClick='edit'>
          <TextField source='title' />
          <ReferenceField
            label='Company'
            source='companyId'
            reference='Company'
          >
            <TextField source='name' />
          </ReferenceField>
          <TextField source='id' />
          <TextField source='state' />
          <DateField source='updatedAt' showTime />
          <EditButton />
        </Datagrid>
      </List>
      <AdBoardCreate
        showCreateDialog={showCreateDialog}
        closeCreateDialog={closeCreateDialog}
      />
    </>
  );
};

export default AdBoardList;
