import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { SectionDto, VideoDto } from '@keyops-hcp/dtos';
import { Loading, useNotify } from 'react-admin';
import { VideoAdBoardSectionDto } from '@keyops-hcp/dtos/dist/dtos/section.dto';

import { EditSectionMenu } from '../EditSectionMenu';
import { VideoUploadCard } from './VideoUploadCard';

import VideoAccordion from './VideoAccordion';
import { useCreateVideoSection } from '../../../../custom-hooks/useCreateVideoSection';
import { useUpdateVideoSection } from '../../../../custom-hooks/useUpdateVideoSection';

interface VideoEditSectionProps {
  editSection: VideoAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}
const VideoEditSection: React.FC<VideoEditSectionProps> = ({
  editSection,
  setEditSection,
}) => {
  const notify = useNotify();
  const [video, setVideo] = useState<VideoDto | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [processing, setProcessing] = useState<boolean>(false);
  const [requiredCommentCount, setRequiredCommentCount] = useState<
    number | undefined
  >(undefined);

  // Use effect to initialize state based on content
  useEffect(() => {
    const { content } = editSection as VideoAdBoardSectionDto;
    if (content && content.video) {
      const initialVideo = content.video;
      setVideo(initialVideo);
      setTitle(initialVideo.title);
      setDescription(initialVideo.description);
      setRequiredCommentCount(content.requiredCommentCount);
    }
  }, [editSection]);

  useEffect(() => {
    const { content } = editSection as VideoAdBoardSectionDto;
    if (content && requiredCommentCount != null) {
      //check for nullish, rather than falsy, as 0 is a value we want to allow
      content.requiredCommentCount = requiredCommentCount;
    }
  }, [requiredCommentCount]);

  // Create the video and the videoSection
  const { mutate: createVideoSection } = useCreateVideoSection(
    editSection,
    setEditSection,
    notify,
    setProcessing
  );

  // Update the video and the videoSection
  const { mutate: updateVideoSection } = useUpdateVideoSection(
    editSection,
    setEditSection,
    notify,
    setProcessing
  );

  const menuOptions = [
    {
      component: (
        <label htmlFor='upload-video'>
          <input
            id='upload-video'
            type='file'
            accept='video/*'
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files?.[0]) {
                createVideoSection({
                  file: e.target.files[0],
                  title,
                  description,
                });
              }
            }}
          />
          Upload video
        </label>
      ),
    },
  ];

  if (processing) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (!video) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <VideoUploadCard createVideoSection={createVideoSection} />
        <EditSectionMenu menuOptions={menuOptions} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <VideoAccordion
        video={video}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        requiredCommentCount={requiredCommentCount}
        setRequiredCommentCount={setRequiredCommentCount}
        updateVideoSection={updateVideoSection}
      />
      <EditSectionMenu menuOptions={menuOptions} />
    </Box>
  );
};

export default VideoEditSection;
