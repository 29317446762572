import React, { ReactNode } from 'react';
import { theme } from '../theme';

export function fetchUrlHashData() {
  // Fetch gd & msgId from the URL hash
  const hash = window.location.hash.substring(1); // Remove the '#' from the hash
  let gd = undefined; // General Discussion
  let msgId = undefined;

  if (hash) {
    // Check if the hash starts with 'gd.'
    if (hash.startsWith('gd.')) {
      gd = true;
      msgId = hash.substring(3); // Extract the part after 'gd.'
    } else {
      msgId = hash; // Handle #msgId format
    }
  }

  return { gd, msgId };
}

export function scrollToMsgId(msgId: string) {
  const element = document.getElementById(msgId);
  // Scroll to the element if it's present
  if (element) {
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (window.history.replaceState) {
        window.history.replaceState(null, null, window.location.pathname);
      }
    }, 750);
  }
}

/**
 * Helper function to extract userIds from a message with Tagify mentions
 * @param message - the message with userIds
 * @example  ​[[{\"id\":\"1000111\",\"value\":\"User A\",\"prefix\":\"@\"}]] and [[{\"id\":\"1000112\",\"value\":\"User B\",\"prefix\":\"@\"}]] ok"
 * @returns string[] - the array of userIds (as string, not number)
 */
export function extractMentionIds(message: string): string[] {
  // Match all occurrences of the tagged mentions
  const mentionRegex = /\[\[{"id":"(\d+)","value":".*?","prefix":"@"}\]\]/g;

  // Array to store the extracted IDs
  const ids: string[] = [];

  // Extract all matches using regex
  let match;
  while ((match = mentionRegex.exec(message)) !== null) {
    ids.push(match[1]); // Push the captured `id` group into the array
  }

  return ids;
}

// TODO: add a regex that catch both the value and the id
export const parseAndHighlightMentions = (
  input: string,
  currentUserId?: string
): ReactNode[] => {
  // Updated regex to capture both `id` and `value`
  const mentionedIdAndValueRegex =
    /\[\[{"id":"(\d+)","value":"([^"]+)","prefix":"@"}]]/g;

  const elements: React.ReactNode[] = [];
  let lastIndex = 0; // To track the position in the string where the last match ended, to keep the plain text between the current match and the previous one.

  let match;
  while ((match = mentionedIdAndValueRegex.exec(input)) !== null) {
    const id = match[1]; // Capture the id
    const value = match[2]; // Capture the value

    // Start index of this match
    const startIndex = match.index;

    // If there’s any text before the current match, it's pushed into the elements array.
    // This ensures that the text between matches is preserved.
    if (lastIndex < startIndex) {
      elements.push(input.substring(lastIndex, startIndex));
    }

    const isCurrentUser = id === currentUserId;

    // Push the mention span
    elements.push(
      <span
        key={value}
        style={{
          backgroundColor: isCurrentUser
            ? theme.palette.keyops.blue.arcticBlue
            : theme.palette.keyops.blue.lightSteelBlue20,
        }}
      >
        {value}
      </span>
    );

    // Ensures == the next match will continue from the correct position in the string.
    lastIndex = mentionedIdAndValueRegex.lastIndex;
  }

  // Push remaining plain text after the last match
  if (lastIndex < input.length) {
    elements.push(input.substring(lastIndex));
  }

  return elements;
};
