import React, { useState } from 'react';
import { Button, Loading } from 'react-admin';
import ReactPlayer from 'react-player';
import { Box } from '@mui/material';

import { VideoDto } from '@keyops-hcp/dtos';
import { UseMutateFunction } from '@keyops-hcp/ui-components';

interface VideoFormProps {
  video?: VideoDto | null;
  title?: string;
  setTitle: (value: string) => void;
  description?: string;
  setDescription: (value: string) => void;
  requiredCommentCount?: number;
  setRequiredCommentCount: (value: number) => void;
  updateVideoSection: UseMutateFunction<
    VideoDto,
    unknown,
    {
      title?: string;
    },
    unknown
  >;
}

// TODO: use react form
export const VideoForm: React.FC<VideoFormProps> = ({
  video,
  requiredCommentCount,
  setRequiredCommentCount,
  updateVideoSection,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <>
      {isLoading ? (
        <>
          <Loading loadingSecondary='It could take several minutes for the video to process' />
        </>
      ) : (
        <ReactPlayer
          url={video?.link}
          onReady={() => {
            setIsLoading(false);
          }}
          onError={() => {
            setIsLoading(true);
          }}
          controls
        />
      )}
      <Box>
        Required comment count:{' '}
        <input
          value={requiredCommentCount}
          onChange={(event) => {
            const value = parseInt(event.target.value);
            setRequiredCommentCount(isNaN(value) ? 0 : value);
          }}
        />
      </Box>
      <Box display={'flex'} flexDirection={'row-reverse'} mt={2}>
        <Button
          label='Save'
          variant={'contained'}
          sx={{ textTransform: 'initial' }}
          onClick={() => updateVideoSection({ title: 'Video for adboard' })}
        />
      </Box>
    </>
  );
};
