import React from 'react';
import { FunctionField, useRecordContext } from 'react-admin';
import { IconButton, Stack, Typography } from '@mui/material';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import { PrimaryTooltip } from '@keyops-hcp/ui-components';
import { getSignedContractUrlByInviteId } from '../../../api/adboard-invites-api';

export const AdBoardInviteContractSignedField = () => {
  const record = useRecordContext(); // Access the current row's record from context

  if (!record) return null; // Safeguard against missing record

  // Contract handling
  const handleDownload = async () => {
    const linkWrapper = await getSignedContractUrlByInviteId(
      record.adBoardId,
      record.id.toLocaleString()
    );
    if (linkWrapper) {
      window.open(linkWrapper.signedUrl, '_blank', 'noreferrer');
    }
  };

  return (
    <FunctionField
      textAlign='center'
      label='Contract signed'
      source='state'
      render={(record) => {
        switch (record.state) {
          case 'contract-signed':
            return (
              <Stack
                direction='row'
                alignItems={'center'}
                justifyContent='center'
              >
                <PrimaryTooltip
                  placement='top'
                  title={
                    <Typography align='center' fontSize={'12px'}>
                      Awaiting signed contract confirmation from DocuSign.
                      <br></br>
                      Signed on: {new Date(record.signedAt).toDateString()}
                    </Typography>
                  }
                  arrow
                >
                  <AccessTimeRoundedIcon
                    fontSize='medium'
                    sx={{ color: 'GrayText' }}
                  />
                </PrimaryTooltip>
              </Stack>
            );
          case 'confirmed':
            return (
              <Stack
                direction='row'
                alignItems={'center'}
                justifyContent='center'
              >
                <PrimaryTooltip
                  placement='top'
                  title={
                    <Typography align='center' fontSize={'12px'}>
                      Signed on: {new Date(record.signedAt).toDateString()}
                    </Typography>
                  }
                  arrow
                >
                  <TaskAltRoundedIcon sx={{ color: 'GrayText' }} />
                </PrimaryTooltip>
                <PrimaryTooltip
                  placement='top'
                  title={
                    <Typography align='center' fontSize={'12px'}>
                      View signed contract
                    </Typography>
                  }
                  arrow
                >
                  <IconButton
                    onClick={handleDownload}
                    sx={{ color: 'GrayText' }}
                  >
                    <AttachFileRoundedIcon />
                  </IconButton>
                </PrimaryTooltip>
              </Stack>
            );
          case 'failed-contract-confirmation':
            return (
              <Stack
                direction='row'
                alignItems={'center'}
                justifyContent='center'
              >
                <PrimaryTooltip
                  placement='top'
                  title={
                    <Typography align='center' fontSize={'12px'}>
                      Issue receiving the signed contract. <br></br>
                      Please check{' '}
                      <a
                        href='https://docs.google.com/document/d/1VSPvq4YLtsoegVAVUeV_36cGwNjLSPEwri8oaGLi3aM/edit?tab=t.0#heading=h.zd1ybnjtbwoa'
                        target='_blank'
                        rel='noopener noreferrer' // Prevents from security vulnerability (controlling original window) and privacy invasion
                      >
                        this guide
                      </a>{' '}
                      to retrieve the signed document. <br></br>
                      Signed on: {new Date(record.signedAt).toDateString()}
                    </Typography>
                  }
                  arrow
                >
                  <WarningAmberRoundedIcon color='warning' fontSize='medium' />
                </PrimaryTooltip>
              </Stack>
            );
          default:
            return <></>;
        }
      }}
    />
  );
};

// Needed in a custom component, otherwise label not displayed
// See https://marmelab.com/react-admin/Fields.html#writing-your-own-field-component
AdBoardInviteContractSignedField.defaultProps = { label: 'Contract signed' };
