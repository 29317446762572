import { AdBoardParticipantsDto } from '@keyops-hcp/dtos';
import { formatAuthorName } from '../common-utils';

// TODO:tests
export const filterParticipants = (
  allParticipantsData: AdBoardParticipantsDto[]
): AdBoardParticipantsDto[] => {
  return allParticipantsData?.filter(
    (participant) => participant.role === 'participant'
  );
};

export const filterModerators = (
  allParticipantsData: AdBoardParticipantsDto[]
): AdBoardParticipantsDto[] => {
  return allParticipantsData?.filter(
    (participant) => participant.role === 'moderator'
  );
};

export const getParticipantsUserListForMentions = (
  allParticipantsData: AdBoardParticipantsDto[],
  currentUserId: number
) => {
  return allParticipantsData
    .filter((participant) => participant.userId !== currentUserId)
    .map((participant, i) => ({
      id: participant.userId.toString(),
      value: formatAuthorName(
        [participant.user.firstName, participant.user.lastName],
        false
      ),
    }));
};
