import React from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { ModeratorChip } from '../ModeratorChip';
import { hoverItemStyle } from '../../styles';
import { PrimaryTooltip } from '../PrimaryTooltip';
import { User } from '../../icons';
import {
  filterModerators,
  filterParticipants,
} from '../../utils/kolab/attendees.utils';
interface AttendeesProps {
  sx?: SxProps;
}

export const Attendees: React.FC<AttendeesProps> = ({ sx }) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { allParticipantsData, isLoading, isError } = useAdBoardContext();

  if (isLoading) return <div>{i18next.t('common.loading')}</div>;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  const participants = filterParticipants(allParticipantsData);
  const moderators = filterModerators(allParticipantsData);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={sx}>
      <PrimaryTooltip title={i18next.t('navbar.hover.attendees')}>
        <IconButton
          id='attendees-button'
          data-testid='attendees-icon-button'
          aria-controls={open ? 'attendees-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          sx={{
            color: palette.keyops.blue.midnightBlue,
            px: 0.5,
            ...hoverItemStyle(),
          }}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          onClick={handleClick}
        >
          <Typography
            variant={'body3'}
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: palette.keyops.black.main,
              mr: 0.5,
            }}
          >
            {allParticipantsData?.length}
          </Typography>
          <User
            sx={{
              fontSize: '25px',
            }}
          />
        </IconButton>
      </PrimaryTooltip>
      <Menu
        id='attendees-menu'
        data-testid='attendees-menu'
        aria-labelledby='attendees-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {participants?.map((participant, i) => (
          <MenuItem key={i} sx={{ minWidth: 240 }}>
            <Stack direction={'row'} alignItems={'center'} gap={1.5}>
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor:
                    participant.role === 'moderator'
                      ? palette.keyops.blue.midnightBlue
                      : palette.keyops.gray[400],
                  color: palette.keyops.white.main,
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                {participant?.user?.firstName[0]}
                {participant?.user?.lastName[0]}
              </Avatar>
              <Typography variant={'body2'}>
                {participant?.user?.firstName} {participant?.user?.lastName}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
        {moderators?.map((moderator, i) => (
          <MenuItem key={i} sx={{ minWidth: 240 }}>
            <Stack direction={'row'} alignItems={'center'} gap={1.5}>
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: palette.keyops.gray[400],
                  color: palette.keyops.white.main,
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                {moderator?.user?.firstName[0]}
                {moderator?.user?.lastName[0]}
              </Avatar>
              <Typography variant={'body2'}>
                {moderator?.user?.firstName} {moderator?.user?.lastName}
              </Typography>
              <ModeratorChip />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
